import React from 'react';
import {
  Container, Select, Label,
} from './styled';
import DownArrowIcon from './DownArrowIcon.svg';

function Dropdown({
  carretStyle,
  options = [],
  update = () => { },
  style,
  value,
  label,
  values,
  required,
  ...other
}) {
  values = values || options;
  let _options = options;

  if (!values.find && typeof (values) === 'object') {
    values = Object.values(values);
    _options = Object.keys(options);
  }

  const _value = value || _options.find((exist) => typeof (exist) !== undefined);

  value = _value;
  label = label || value;
  return (
    <Container style={{ ...style }}>
      <img style={carretStyle} src={DownArrowIcon} />
      <Select
        {...other}
        id={label}
        required={required}
        value={value}
        onChange={({ target: { value } }) => {
          const index = values.indexOf ? values.indexOf(value) : null;
          update(value, index);
        }}
      >
        {_options.map((option, index) => (
          (typeof option === 'undefined') ? null :
            <option value={values[index]} key={index}>
              {option}
            </option>
        ))}
      </Select>
    </Container>
  );
}

export default Dropdown;
