import React from 'react';
import styled from 'styled-components';
import Body from '../Text/Body';

const Container = styled.button`
  width: ${props => props.small ? 'auto' : '100%'};
  height: ${props => props.small ? 'auto' : '61px'};
  padding: ${props => props.small ? '10px' : '0'};
  background-color: ${props => props.small ? '#ECECEC' : 'black'};
  border-radius: ${props => props.small ? '8px' : '18px'};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  cursor: pointer;
  max-width:${props => props.small ? 'none' : '327px'};
  outline: none;
  border: none;
  ${props => props.small ? `> div {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: none !important;
    color: black !important;
  }` : null}
`;

function Button({
  label, style, onClick = () => {}, type, color = 'white', small: _small,
}) {
  const small = typeof(_small) !== 'undefined';
  return (
    <Container small={small} type={type} onClick={onClick} style={style}>
      <Body style={{ color, textTransform: 'uppercase' }}>{label}</Body>
    </Container>
  );
}

export default Button;
