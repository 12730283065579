import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${props => props.showOutline ? '1px solid black' : '1px solid #ECECEC'};
  width: 333px;
  height: 59px;
  background-color: ${props => props.showOutline ? 'white' : '#ECECEC'};
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: calc(375px - 40px);
  ::-ms-expand {
    display: none;
  }
  img {
    top: 50%;
    z-index: 1;
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, -50%);
    right: 20px;
    width: 10px;
    height: 10px;
  }
`;

export const Select = styled.select`
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    /* top: -webkit-calc(50% - 5px); */
  }
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  height: 50px;
  width: calc(100%);
  background-color: transparent;
  border: none;
  font-family: PublicSans-Regular;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  padding: 0 20px;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  :disabled {
  }
`;

export const Label = styled.label`
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    top: -webkit-calc(50% - 18px);
  }
  left: 20px;
  top: calc(50% - 18px);
  z-index: 1;
  -webkit-appearance: none;
  transition: font-size 0.1s ease-in;
  font-family: PublicSans-Light;
  font-size: 10px;
  color: ${props => props.theme['text-color']};
  line-height: 12px;
  position: absolute;
  text-transform: uppercase;
  pointer-events: none;
`;