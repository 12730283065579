import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border: ${props => props.showOutline ? '1px solid black' : '1px solid #ECECEC'};
  width: 333px;
  height: 59px;
  background-color: ${props => props.showOutline ? 'white' : '#ECECEC'};
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: calc(375px - 40px);
  input {
    border-radius: 18px;
    outline: none;
      border: none;
      width: 100%;
      height: 100%;
      padding: 0 16px;
      background-color: transparent;
    ::placeholder {
      color: #999999;
    }
  }
`;

function Input({
  label, style, update = () => {}, value, max, mask, ...other
}) {
  let showOutline = false;

  if (value && value.length >= max) {
    showOutline = true;
  }

  return (
    <Container showOutline={showOutline} style={style}>
      <input {...other} placeholder={label} value={value} onChange={(e) => {
        let valueAfterMask = e.target.value;

        if (mask) {
          valueAfterMask = mask(valueAfterMask);
        }
        update(valueAfterMask);
      }}
      />
    </Container>
  );
}

export default Input;

export const InputStyles = Container;
