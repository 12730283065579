import styled from 'styled-components';

export const Container = styled.div`
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: left;
  color: ${props => (props.color ? props.color : '#101011')};
  text-align: center;
  width: 100%;
`;


