import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

function Row({ children, style }) {
  return (
    <Container style={style}>
      {children}
    </Container>
  );
}

export default Row;
