import styled from 'styled-components';

export const Container = styled.div`
  font-family: Manrope;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #FFFFFF;
  text-align: center;
  width: 100%;
`;