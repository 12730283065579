import React, {
  useState, useEffect, useCallback,
} from 'react';
import styled from 'styled-components';
import Input from '../components/Input';
import Body from '../components/Text/Body';
import Flexor from '../components/Flexor';
import Button from '../components/Button';
import Dropdown from '../components/Dropdown';
import { sendDropMessage, getWinner } from '../helpers/API';
import Row from '../components/Row';
import Column from '../components/Column';
import H3 from '../components/Text/H3';
const password = process.env.ADMIN_PASSWORD;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  > div {
    margin-bottom: 50px;
  }
`;

function Admin() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (!authenticated) {
      const _password = prompt('Enter password');

      if (_password === password) {
        setAuthenticated(true);
      } else {
        alert('Wrong password');
        window.location.reload();
      }
    }
  }, [authenticated]);

  const content = (
    <Row style={{ width: 'calc(100% - 120px)', alignItems: 'center' }}>
      <MessageSender />
      <WinnerGenerator />
    </Row>
  );
  return (
    <Container>
      {authenticated && content}
    </Container>
  );
}

function WinnerGenerator() {
  const [winner, setWinner] = useState(null);
  const [dropId, setDropId] = useState(null);
  const _getWinner = useCallback(async () => {
    const _winner = await getWinner(dropId);
    setWinner(_winner);
  }, [dropId]);
  return (
    <Column style={{ alignItems: 'center' }}>
      <H3 style={{ color: 'black' }}>{winner || 'Press Button Below To Choose Winner at Random'}</H3>
      <Flexor flex="0 0 20px" />
      <Input required update={setDropId} value={dropId} label="Drop Id" />
      <Flexor flex="0 0 20px" />
      <Button onClick={_getWinner} label="Generate Winner" />
    </Column>
  );
}


function MessageSender() {
  const [message, setMessage] = useState(null);
  const [dropId, setDropId] = useState(null);
  const [groupSubset, setGroupSubset] = useState(null);
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    sendDropMessage({ dropId: dropId, message }).then((res) => {
      if (res === 'OK') {
        setMessage(null);
        setDropId(null);
        alert('Message sent');
      }
    });
    return false;
  }, [dropId, message]);
  return (
    <Column as="form" style={{ marginRight: 30 }} onSubmit={handleSubmit}>
      <Flexor flex="0 0 20px" />
      <Body>Enter message to send to drop group</Body>
      <Flexor flex="0 0 20px" />
      <Input required update={setDropId} value={dropId} label="Drop Id" />
      <Flexor flex="0 0 20px" />
      <Input required update={setMessage} value={message} label="Message" />
      <Flexor flex="0 0 20px" />
      <Dropdown label="" update={setGroupSubset} value={groupSubset} options={['All', 'Winners', 'Losers']} />
      <Flexor flex="0 0 20px" />
      <Button type="submit" label="Send" />
    </Column>
  );
}

export default Admin;
