import * as fetchHelpers from '../Fetch';
const API_URL = fetchHelpers.API_URL;

export function getPaymentSecret(amount) {
  const payload = { amount };
  return fetchHelpers
    .makeRequest(API_URL + 'subscribe/intent', payload)
    .send();
}

export function sendVerificationCode(phoneNumber) {
  const payload = { phoneNumber };
  return fetchHelpers
    .makeRequest(API_URL + 'user/phone', payload)
    .send();
}

export function checkVerificationCode(phoneNumber, code) {
  const payload = { phoneNumber, code };
  return fetchHelpers
    .makeRequest(API_URL + 'user/phone', payload)
    .setMethod('PUT')
    .send();
}

export function addUserToDrop(dropId, receiptId, amount, email, itemName, amountOfEntries) {
  const payload = {
    dropId, receiptId, amount, email, itemName, amountOfEntries,
  };
  return fetchHelpers
    .makeRequest(API_URL + 'subscribe', payload)
    .setMethod('POST')
    .send();
}

export function sendDropMessage({ dropId, message }) {
  const payload = { message };
  return fetchHelpers
    .makeRequest(API_URL + `drops/${dropId}/message`, payload)
    .setMethod('POST')
    .send();
}

export function getWinner(dropId) {
  return fetchHelpers
    .makeRequest(API_URL + `drops/${dropId}/winner`)
    .setMethod('GET')
    .send();
}